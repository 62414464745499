import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setModal } from '/components/common/modal/model'
import { Carousel } from '../common/carousel/Carousel'
import { HorizontalCard } from '../common/cards/horizontal-card/HorizontalCard'
import { useNavigate } from '@reach/router'
import { getLastAddedTvShows } from './tvShowsActions'
import { GEOBLOCK_MESSAGE } from '/utils/constants'
import * as tvShowActions from '../tvShows/tvShowsActions'
import { _ } from '/config'

const TvShowsLastAdded = ({
  content,
  cardType,
  handleMovieClick = null,
  id,
  getLastAddedTvShows,
  setIsTvShowFavorite,
}) => {
  const navigate = useNavigate()
  const maxLength = 36
  const { t } = useTranslation('home')

  useEffect(() => {
    getLastAddedTvShows && getLastAddedTvShows({ count: maxLength })
  }, [])

  const handleClick = (show) => {
    if (show?.blockedByAcl) {
      setModal({
        text: t(`common:message.${GEOBLOCK_MESSAGE}`, {
          item: `"${show.name}"`,
        }),
      })
      return
    }

    handleMovieClick && handleMovieClick(show)
    navigate(`/shows/categories/0/${show.id}`)
  }

  return content ? (
    <Carousel
      categoryId={'lastAddedSeries'}
      key={"'lastAddedSeries'"}
      carouselTitle={t(_('last-added-tv-series'))}
      showAllOption={false}
      id={id}
    >
      {content.map((item) => {
        return (
          <HorizontalCard
            favorite={item.favorite}
            handleFavoriteClick={() => setIsTvShowFavorite(item)}
            key={`${item.id}/${item.name}`}
            title={item.name}
            imageUrl={item.landscapeImageUrl || item.backgroundImageUrl}
            handleClick={() => handleClick(item)}
          />
        )
      })}
    </Carousel>
  ) : null
}

function mapStateToProps(state) {
  return {
    content: state.views.tvShows.lastAddedTvShows,
  }
}

const mapDispatchToProps = {
  getLastAddedTvShows: getLastAddedTvShows,
  setIsTvShowFavorite: tvShowActions.setTvShowIsFavorite,
}

export const TvShowsCarouselLastAdded = connect(
  mapStateToProps,
  mapDispatchToProps
)(TvShowsLastAdded)
