export const FORM_TYPE = {
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  LOGIN: 'LOGIN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  VERIFY_RESET_CODE: 'VERIFY_RESET_CODE',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
};

export const REGISTER_STATUS = {
  WAITING_EMAIL_CONFIRMATION: 'WAITING_EMAIL_CONFIRMATION',
  IN_PROCESS: 'IN_PROCESS',
  DONE: 'DONE',
  EXPIRED: 'EXPIRED',
  FAILED: 'FAILED',
};

export const VALIDATION_ERROR_MESSAGE = {
  FIELD_IS_REQUIRED: '{{field}} is required',
  EMAIL_PATTERN_MISMATCH: 'Please, provide a valid email address',
  PASSWORD_PATTERN_MISMATCH:
    'Sorry, only letters (aA-zZ), numbers (0-9), and symbols (@#$ _&-*!?.) are allowed',
  PASSWORD_TOO_LONG: 'Password is too long, maximum length is 64 characters',
  NOT_EQUAL_PASSWORDS: 'The password and confirm password fields do not match',
};

export const GEOBLOCK_MESSAGE = '{{item}} is not available in your region.';

export const WARNING = {
  PASSWORD_UPDATED: 'Password has been updated',
};

export const TIME_PERIODS = {
  HOURS: 'Hours',
  MINUTES: 'Minutes',
  HOUR: 'Hour',
  MINUTE: 'Minute',
};

enum timePeriods {
  HOUR = 'HOUR',
  MINUTE = 'MINUTE'
}

export const getTimePeriod = (value: number, period: string) => {
  switch (period) {
    case timePeriods.HOUR: return value === 1 ? TIME_PERIODS.HOUR: TIME_PERIODS.HOURS;
    case timePeriods.MINUTE: return value === 1 ? TIME_PERIODS.MINUTE: TIME_PERIODS.MINUTES;
    default:
      return '';
  }
};
/* id: "f7700119-bed1-4f84-ba3c-ed6543c63d06"
time_period: "MINUTE"
time_value: 3 */
