import { categoriesPath, Route, subCategoriesPath } from '~/root/routes'
import { TvShowsHomePage } from '../pages/home'
import { TvShowDetailsPage } from '../pages/details'
import { TvShowPlayerPage } from '../pages/TvShowPlayerPage'
import { TvShowsCategoryPage } from '/components/tvShows/pages/category'
import { _ } from '/config'

export const path = 'shows'

export const tvShowsRoutes = (tvShowEnabled: boolean): Route[] => [
  {
    path: `/${path}`,
    name: _('tv-show-category-series'),
    component: TvShowsHomePage,
    enable: tvShowEnabled,
    main: true,
  },
  {
    path: `/${path}/:tvShowId`,
    name: _('tv-show-category-series'),
    component: TvShowDetailsPage,
    enable: tvShowEnabled,
  },
  {
    path: `/${path}/:tvShowId/:episodeId`,
    name: _('tv-show-category-series'),
    component: TvShowPlayerPage,
    enable: tvShowEnabled,
  },
  {
    path: `/${path}/:tvShowId/:seasonId/:episodeId`,
    name: _('tv-show-category-series'),
    component: TvShowPlayerPage,
    enable: tvShowEnabled,
  },
  {
    path: `/${path}/${categoriesPath}`,
    name: _('tv-show-category-series'),
    component: TvShowsHomePage,
    enable: tvShowEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId`,
    name: _('tv-show-category-series'),
    component: TvShowsCategoryPage,
    enable: tvShowEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId/:tvShowId`,
    name: _('tv-show-category-series'),
    component: TvShowDetailsPage,
    enable: tvShowEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId/:tvShowId/:episodeId`,
    name: _('tv-show-category-series'),
    component: TvShowPlayerPage,
    enable: tvShowEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId/:tvShowId/:seasonId/:episodeId`,
    name: _('tv-show-category-series'),
    component: TvShowPlayerPage,
    enable: tvShowEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId/${subCategoriesPath}`,
    name: _('tv-show-category-series'),
    component: TvShowsCategoryPage,
    enable: tvShowEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId/${subCategoriesPath}/:subCategoryId`,
    name: _('tv-show-category-series'),
    component: TvShowsCategoryPage,
    enable: tvShowEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId/${subCategoriesPath}/:subCategoryId/:tvShowId`,
    name: _('tv-show-category-series'),
    component: TvShowDetailsPage,
    enable: tvShowEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId/${subCategoriesPath}/:subCategoryId/:tvShowId/:episodeId`,
    name: _('tv-show-category-series'),
    component: TvShowPlayerPage,
    enable: tvShowEnabled,
  },
  {
    path: `/${path}/${categoriesPath}/:categoryId/${subCategoriesPath}/:subCategoryId/:tvShowId/:seasonId/:episodeId`,
    name: _('tv-show-category-series'),
    component: TvShowPlayerPage,
    enable: tvShowEnabled,
  },
]
