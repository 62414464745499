import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from '@reach/router'
import { setModal } from '/components/common/modal/model'
import * as tvShowActions from '../tvShowsActions'
import { useTranslation } from 'react-i18next'
import { Carousel } from '../../common/carousel/Carousel'
import { HorizontalCard } from '/components/common/cards/horizontal-card/HorizontalCard'
import { GEOBLOCK_MESSAGE } from '/utils/constants'
import { _ } from '/config'

const FavoriteTvShows = ({
  movies,
  handleMovieClick,
  loadContent,
  id,
  setIsTvShowFavorite,
}) => {
  const maxLength = 36
  const navigate = useNavigate()
  const { t } = useTranslation('home')

  useEffect(() => {
    loadContent({ page: 0, count: 36 })
  }, [])

  const handleClick = async (show) => {
    if (show?.blockedByAcl) {
      setModal({
        text: t(`common:message.${GEOBLOCK_MESSAGE}`, {
          item: `"${show.name}"`,
        }),
      })
      return
    }
    handleMovieClick && handleMovieClick(show)

    await navigate(`/shows/categories/0/${show.id}`)
  }

  const handleFavoriteClick = (item) => () => {
    setIsTvShowFavorite(item)
  }

  return (movies || [])?.length ? (
    <Carousel
      key={"'favoriteTvShows'"}
      carouselTitle={t(_('favorite-tv-series'))}
      showAllOption={false}
      id={id}
    >
      {(movies || []).slice(0, maxLength).map((item) => {
        return (
          <HorizontalCard
            favorite={item.favorite}
            handleFavoriteClick={handleFavoriteClick(item)}
            key={`${item.id}${item.name}`}
            title={item.name}
            imageUrl={item.landscapeImageUrl || item.backgroundImageUrl}
            handleClick={() => handleClick(item)}
          />
        )
      })}
    </Carousel>
  ) : null
}

function mapStateToProps(state) {
  return {
    movies: state.views.tvShows.favoriteTvShows,
  }
}

const mapDispatchToProps = {
  loadContent: tvShowActions.getOnlyFavoriteTvShows,
  setIsTvShowFavorite: tvShowActions.setTvShowIsFavorite,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const FavoriteTvShowsCarousel = connector(FavoriteTvShows)
