import React from 'react'
import { connect } from 'react-redux'
import { useStore } from 'effector-react'
import { $isCarouselsEnabled } from '/models/carousels'
import { $account, $backendVersion } from '/models/account'
import { $continueWatching } from '/models/continueWatching'
import { useTranslation } from 'react-i18next'
import { BannerCarouselContainer } from '../components/BannerCarousel'
import { TvCarousel } from '/components/tv/TvCarousel'
import { TvShowsCarouselContainer } from '/components/tvShows/components/TvShowsCarousel'
import { TvCarouselRecentlyWatched } from '/components/tv/TvCarouselRecentlyWatched'
import { CatchupsCarouselRecentlyWatched } from '../../catchup/CatchupsCarouselRecentlyWatched'
import { ContinueWatchingCarousel } from '../ContinueWatchingCarousel'
import { VodsCarouselContainer } from '/components/vod/VodsCarousel'
import { CardTypes } from '/utils/helpers'
import { VodsCarouselLastAdded } from '/components/vod/components/VodsCarouselLastAdded'
import { TvShowsCarouselLastAdded } from '/components/tvShows/TvShowsCarouselLastAdded'
import { NoContent } from '../../common/no-content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FavoriteVodsCarousel } from '/components/vod/components/FavoriteVodsCarousel.tsx'
import { FavoriteTvShowsCarousel } from '/components/tvShows/components/FavoriteTvShowsCarousel.tsx'
import {
  faCreditCardBlank,
  faVideoSlash,
} from '@fortawesome/pro-regular-svg-icons'
import { _ } from '/config'
import { EmptyCarousel } from '../../common/empty-carousel'
import { path as homePath } from '/components/home/routes'

import { isAnyContent, isTVContent } from '../store/selectors'

const HomePage = ({ anyContent, tvContent }) => {
  const isCarouselsEnabled = useStore($isCarouselsEnabled)
  const { catchupEnabled, tvEnabled, tvShowEnabled, vodEnabled } =
    useStore($account)
  const backendVersion = useStore($backendVersion)
  const continueWatching = useStore($continueWatching)
  const { t } = useTranslation('home')

  return (
    <div className='content-home'>
      {isCarouselsEnabled ? (
        <>
          <BannerCarouselContainer page={homePath} />
          <TvCarousel
            cardType={CardTypes.tvChannelCard}
            id={'homeTVFeatured'}
            title={t('Featured Live Channels')}
          />
          <VodsCarouselContainer
            cardType={CardTypes.baseCard}
            id={'homeVODSFeatured'}
            title={t(_('header-featured-movies'))}
          />
          <TvShowsCarouselContainer
            cardType={CardTypes.baseCard}
            id={'homeSHOWSFeatured'}
            title={t(_('tv-show-featured-series'))}
          />
        </>
      ) : !tvShowEnabled &&
        !vodEnabled &&
        !catchupEnabled &&
        tvEnabled &&
        !tvContent ? (
        <NoContent
          title={t('home:Here will be content')}
          subtitle={t('home:Based on your preferences')}
          icon={
            <FontAwesomeIcon
              icon={faCreditCardBlank}
              className={'noContentIcon'}
            />
          }
        >
          <EmptyCarousel />
        </NoContent>
      ) : !anyContent && !continueWatching?.length ? (
        <NoContent
          icon={<FontAwesomeIcon icon={faVideoSlash} className='icon' />}
          title={t('common:No content')}
        />
      ) : null}

      {tvEnabled && <TvCarouselRecentlyWatched id={'homeRecentlyTV'} />}
      {catchupEnabled && (
        <CatchupsCarouselRecentlyWatched id={'homeRecentlyCatchups'} />
      )}
      {vodEnabled && <ContinueWatchingCarousel id={'homeContinueVods'} />}
      {vodEnabled && (
        <VodsCarouselLastAdded
          cardType={CardTypes.tvChannelCard}
          id={'homeLastVods'}
        />
      )}
      {tvShowEnabled && (
        <TvShowsCarouselLastAdded
          cardType={CardTypes.baseCard}
          id={'homeLastShows'}
        />
      )}
      {backendVersion[0] >= '1.42' && vodEnabled && (
        <FavoriteVodsCarousel id={'homeFavoriteVods'} />
      )}
      {backendVersion[0] >= '1.42' && tvShowEnabled && (
        <FavoriteTvShowsCarousel id={'homeFavoriteTvShows'} />
      )}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    anyContent: isAnyContent(state),
    tvContent: isTVContent(state),
  }
}

export const HomePageContainer = connect(mapStateToProps)(HomePage)
