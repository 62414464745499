import React, { useRef, useState, useCallback } from 'react'
import { useStore } from 'effector-react'
import { doLogin } from '/models/login'
import {
  $registrationEnabled,
  $resetPasswordEnabled,
  setForm,
  resetError
} from '/models/registration'
import { reset } from '../../models/forgotPassword'
import { Logo } from './FormLogo'
import { Button } from '/components/common/button'
import { ErrorBlock } from '../error_components/ErrorBlock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockAlt, faUserCircle } from '@fortawesome/pro-solid-svg-icons'
import { FORM_TYPE, VALIDATION_ERROR_MESSAGE } from '/utils/constants'
import { capitalizeFirstLetter } from '/utils/helpers'
import { customize } from '/config'

import s from '../../login.module.scss'

export const SignIn = (): JSX.Element => {
  const [fieldError, setError] = useState('')
  const emailRef = useRef<HTMLInputElement>(null)
  const passRef = useRef<HTMLInputElement>(null)
  const registrationEnabled = useStore($registrationEnabled)
  const resetPasswordEnabled = useStore($resetPasswordEnabled)

  const forgotPasswordHandleClick = () => {
    reset()
    setForm(FORM_TYPE.RESET_PASSWORD)
  }

  const validate = (field) => {
    // do one validation for two forms
    field.className = field.validity.valid ? '' : `${s.invalid}`

    if (field.validity?.valueMissing) {
      const message = VALIDATION_ERROR_MESSAGE.FIELD_IS_REQUIRED.replace(
        '{{field}}',
        field.name
      )
      setError(capitalizeFirstLetter(message))
    }
    if (field.validity?.patternMismatch) {
      if (field.name === 'email')
        setError(VALIDATION_ERROR_MESSAGE.EMAIL_PATTERN_MISMATCH)
      if (field.name === 'password')
        setError(VALIDATION_ERROR_MESSAGE.PASSWORD_PATTERN_MISMATCH)
    }
    if (field.validity?.tooLong && field.name === 'password') {
      setError(VALIDATION_ERROR_MESSAGE.PASSWORD_TOO_LONG)
    }
  }

  const submit = (e) => {
    reset()
    setError('')
    e.preventDefault()
    const email = emailRef?.current
    const password = passRef?.current

    if (email && password) {
      validate(password)
      validate(email)

      if (email.validity.valid && password.validity.valid) {
        doLogin({
          email: email.value,
          password: password.value,
        })
      }
    }
  }

  const handleSubmitButton = () => resetError()

  const handleButton = () => {
    if (customize.signup.externalUrl) {
      window.location.href = customize.signup.externalUrl
      return;
    }

    reset()
    setForm(FORM_TYPE.SIGN_UP)
  }

  return (
    <>
      <div
        className={`${s.loginBox} ${
          registrationEnabled ? `${s.loginBox__staticHeight}` : ''
        }`}
      >
        <div className={s.loginHeader}>
          <Logo />
          <h1 className={s.loginTitle}>Welcome, Dear Friend!</h1>
        </div>
        <form className={s.loginForm} onSubmit={submit} noValidate>
          <div className={s.loginForm__field}>
            <FontAwesomeIcon
              className={s.loginForm__icon}
              icon={faUserCircle}
            />
            <div className={s.loginForm__label}>
              <input
                required
                ref={emailRef}
                id='email'
                name='email'
                type='text'
                placeholder={' '}
              />
              <label htmlFor='email'>Email or User Name</label>
            </div>
          </div>
          <div className={s.loginForm__field}>
            <FontAwesomeIcon className={s.loginForm__icon} icon={faLockAlt} />
            <div className={s.loginForm__label}>
              <input
                required
                ref={passRef}
                id='password'
                name='password'
                type='password'
                maxLength={64}
                pattern='^[A-Za-z0-9@#$_&-*!?.]+$'
                placeholder={' '}
              />
              <label htmlFor='password'>Password</label>
            </div>
          </div>
          <Button
            type='submit'
            title='Sign In'
            className={`${s.button} button-primary button-small button-full-width`}
            handleClick={handleSubmitButton}
          />
          {resetPasswordEnabled && (
            <div className={s.forgotPassword}>
              <span className={`${s.message}`}>Forgot your password?</span>{' '}
              <a className={s.link} onClick={forgotPasswordHandleClick}>
                Click here
              </a>
            </div>
          )}
          {registrationEnabled && (
            <>
              <p className={`${s.message} ${s.messageMarginTop}`}>
                Don`t have an account?
              </p>
              <Button
                type='button'
                title='Sign Up'
                className={`${s.button} button-ghost button-small button-full-width`}
                handleClick={handleButton}
              />
            </>
          )}
        </form>
      </div>
      {fieldError ? <ErrorBlock error={{ message: fieldError }} /> : null}
    </>
  )
}
